.rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  flex: 1 1 240px;
  min-width: 180px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);

  img {
    display: block;
    margin: 10px auto;
  }

  ol {
    padding: 0 40px;
    max-width: 400px;

    li {
      margin: 7px auto;

      .item {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        color: #000;

        .title {
          font-weight: 500;
          margin-right: 10px;
        }

        .author {
          color: rgb(100, 100, 100);
        }
      }
    }
  }
}
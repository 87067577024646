.footer {
  height: 135px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin: 20px 0 0 0;
  background: url("../../static/footer.png") no-repeat center 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    font-size: 10px;
    margin: 2px 0;
    font-weight: 500;
  }
}

html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
  width: 100%;
}

html {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background: url("./static/background.png") repeat;
}

.App {
  display: flex;
  flex-direction: column;

  header {
    flex: 0 0 auto;
  }

  main {
    flex: 1 0 auto;
    // width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
  }

  footer {
    flex: 0 0 auto;
  }
}
.detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  .breadcrumb {
    margin: 20px 0;
    font-size: 20px;
    .option {
      cursor: pointer;
      margin: 0 20px;
      &:hover {
        color: brown;
      }
    }
  }

  .player {
    width: 95%;
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .chapters {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      cursor: pointer;
      width: 25%;
    }
  }

  .content {
    line-height: 2;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .left {
      flex: 3 3 600px;
      padding: 20px;

      .title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }

      .subtitle {
        font-weight: 800;
        margin-top: 30px;
      }

      .author {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }

      .poem {
        text-align: center;
      }

      .translation {
        text-indent: 2em;
      }
    }

    .divider {
      flex: 0 0 2px;
      margin: 0 1% 0 1%;
      background-color: rgba(59, 61, 61, 0.2);
    }

    .right {
      flex: 1 1 200px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .role {
        margin-top: 30px;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 886px) {
    .player {
      width: 100%;
    }

    .content {
      .divider {
        background-color: rgba(255, 255, 255, 0);
      }

      .right .role {
        margin-top: 10px;
      }
    }
  }
}

.carousel {
  // height: 100px;
  flex: 3 1 780px;
  display: flex;

  .left {
    flex: 1 1 180px;

    .thumbnails {
      display: flex;
      flex-direction: column;

      .preview {
        margin: 0;
        height: auto;
        width: 100%;
        display: flex;
        background-color: #000;

        img {
          cursor: pointer;
          height: auto;
          width: 100%;
          transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          opacity: 0.6;

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  .right {
    flex: 4 1 720px;
    display: inline;
    position: relative;

    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      margin: 0;
    }

    .footer {
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: 3px;
      height: 80px;
      background-color: rgba($color: #000000, $alpha: 0.5);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .poem {
        font-weight: 500;
      }

      .poem,
      .performer {
        display: flex;
        justify-content: space-around;
        align-items: center;

        div span {
          word-break: keep-all;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
    justify-items: flex-start;

    .right {
      flex: 0 0 auto;

      .footer {
        font-size: 12px;
      }
    }

    .left {
      flex: 0 0 auto;

      .thumbnails {
        flex-direction: row;
      }
    }
  }

}
.dynasty {


  .title {
    display: block;
    margin: 20px auto;
  }

  .options {
    display: flex;
    justify-content: space-around;

    img {
      max-width: 20%;
      height: auto;
    }
  }

  .list {
    text-align: center;
    line-height: 2;

    .listItem {
      text-decoration: none;
      display: block;
    }

    .title {
      font-weight: 500;
    }
  }
}